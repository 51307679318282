import EventEmitter from './models/event-emitter.model';
import { DentoloEventName as Events } from './constants';
import { yaMetrikaCounter } from './ya-metrika';

export function getEmitterForYAMetrika(emitter: EventEmitter<Events>) {

	Object.values(Events).forEach(event => {
		switch (event) {

			case Events.INIT: {
				emitter.subscribe(event, payload => {
					yaMetrikaCounter.exec('params', { servicesNum: payload.servicesNum });
					yaMetrikaCounter.exec('reachGoal', event);
				});
				break;
			}

			case Events.DEFINE_SERVICE: {
				emitter.subscribe(event, payload => {
					yaMetrikaCounter.exec('reachGoal', event, { service: payload.service });
				});
				break;
			}

			case Events.GET_CLIENT_INFO: {
				emitter.subscribe(event, payload => {
					yaMetrikaCounter.exec('params', { client: payload.name })
				});
				break;
			}

			case Events.DEFINE_AB_TEST_CASE: {
				emitter.subscribe(event, payload => {
					yaMetrikaCounter.exec('params', { ab_tests: payload.ab_tests });
				});
				break;
			}

			case Events.OPEN: {
				emitter.subscribe(event, payload => {
					yaMetrikaCounter.exec('reachGoal', event, { openTrigger: payload.trigger });
				});
				break;
			}

			case Events.CHOOSE_SERVICE: {
				emitter.subscribe(event, payload => {
					yaMetrikaCounter.exec('reachGoal', event, { service: payload.service });
				});
				break;
			}

			case Events.RICH_QUESTION: {
				emitter.subscribe(event, payload => {
					if (payload?.question) {
						yaMetrikaCounter.exec('reachGoal', `${event}_${payload.question}`);
					}
				});
				break;
			}

			case Events.REACH_PHONE_FORM: {
				emitter.subscribe(event, payload => {
					yaMetrikaCounter.exec('reachGoal', event, { phoneForm: payload.form });
				});
				break;
			}

			case Events.GET_ECOMMERCE_ACTION: {
				emitter.subscribe(event, payload => window.dentolo_quiz_ec.push({ ecommerce: payload.ecommerce }));
				break;
			}

			case Events.SEND_PARAMS: {
				emitter.subscribe(event, payload => {
					const { type, ...params } = payload ?? {};
					yaMetrikaCounter.exec('params', params)
				});
				break;
			}

			case Events.CLOSE_WITH_ERROR: break;

			default: emitter.subscribe(event, () => {
				yaMetrikaCounter.exec('reachGoal', event);
			});
		}
	});
}
