import device from 'current-device';
import { Dentolo } from './dentolo';
import Iframe from './models/iframe/iframe.model';
import { PostMessageData } from './dispatchers/post-message.dispatcher';
import scrollEmitter from './emitters/scroll.emitter';
import { OPEN_QUIZ, CLOSE_QUIZ, WIDGET_ERROR, INIT, REPLACE_PHONE, INIT_ERROR, STANDART_UTM_MARKS, CUSTOM_LEAD_PARAMS, DentoloEventName, Service, REPLACE_DESCRIPTOR, TEST_VARIATIONS, CUSTOM_CONTENT, ENABLE_WEBVISOR } from './constants';
import EventEmitter from './models/event-emitter.model';
import { checkOptionsForErrors, checkWidgetOptionsForErrors } from './error-checking';
import { getQueryParams, getQueryParam, checkForEnable, getCookie } from './utils';
import * as Sentry from '@sentry/browser';
import styles from './index.module.scss';

import { getEmitterForYAMetrika } from './ya-metrika-subscriber';
import { yaMetrikaCounter } from './ya-metrika';
import { appConfig } from "./config/app.config";
import { getCombination } from "./get-combanation";
import { StorageProvider } from "./models/storage.provider";

import UAParser, { IResult } from 'ua-parser-js';

if (document.location.href.includes("vse-svoi.ru/msk/online")) {
	Sentry.init({
		dsn: "https://28d78afed16a8f9b5c967c5e949c7eb6@o394454.ingest.us.sentry.io/4507390541299712",
		sampleRate: 1
	});
}

declare global {
	interface Window {
		DentoloQuiz: Function;
		ym: any,
		dentolo_quiz_ec: any[],
		google_optimize: any,
		dataLayer: any,
		dentolo_quiz: Dentolo.Api
	}
}

if (appConfig().global_object) window.DentoloQuiz = DentoloQuiz;

interface Store {
	clientId: string;
	serviceList: Service[];
	loadingStrategy: Dentolo.LoadingStrategy;
	autopopupTimeout: number;
	autopopupLimit: number;
	openOnScroll: boolean;
	initialPageIndex: number;
	autopopupTimer?: NodeJS.Timeout;
	loadingDelay?: number;
	iframe?: Iframe;
	dentoloEventEmitter: EventEmitter<DentoloEventName>;
	currentError?: string;
	widget?: Dentolo.IWidget;
	widgetOptions?: Dentolo.WidgetOptions;
	queryParams: { [key: string]: string };
	customLeadParams?: { [key: string]: string };
	memorizedScrollPosition: number;
	scenario: Dentolo.Scenario;
	storageProvider: StorageProvider;
	develop?: boolean;
	servicesNum: number;
	whatsApp?: {
		enable: boolean
		phone: string
	}
	bonuses: boolean | undefined;
	yaClientID?: string;
	commonServiceList?: Service[];
	showCommonServiceList?: boolean;
	customIframe?: string;
	testVariations?: Dentolo.ConvrTestVariation[];
	go_test?: boolean;
	customContent?: Dentolo.ICustomContent;
	closeButton?: {
		show: boolean;
		onClick: string;
		target: "_self" | "_blank"
	},
	quiz_host: string;
	theme: "light" | "dark";
	uaParserResult?: IResult;
	enableGeolocation?: boolean;
	startPageAutoAction?: {
		action: "start_quiz" | "open_sign_up",
		timer: number //seconds
	}
}

const store: Store = {
	clientId: '',
	loadingStrategy: 'onDemand',
	autopopupTimeout: 10000,
	initialPageIndex: 0,
	openOnScroll: false,
	serviceList: Object.values(Service).filter(service => (service != Service.CLEANING && service != Service.REMOVE_WISDOM && service != Service.ONLY_BRACES && service != Service.ONLY_ELINERS && service != Service.BITE && service != Service.CHEAP_PROSTHETICS && service != Service.NO_PALATE_PROSTHETICS)),
	dentoloEventEmitter: new EventEmitter<DentoloEventName>(),
	queryParams: getQueryParams(STANDART_UTM_MARKS),
	memorizedScrollPosition: document.documentElement.scrollTop,
	scenario: 'popup',
	autopopupLimit: 1,
	storageProvider: new StorageProvider(),
	servicesNum: 0,
	bonuses: undefined,
	go_test: true,
	customContent: undefined,
	quiz_host: appConfig().quiz_url,
	theme: "light",
	enableGeolocation: false
};

const state: { [key: string]: boolean } = {
	pristine: true,
	inited: false
};

const api: Dentolo.Api = {
	init,
	bind,
	unbind,
	setAutopopupTimeout,
	isInited,
	open(): never { throw new Error(INIT_ERROR) },
	close(): never { throw new Error(INIT_ERROR) },
	widget: {
		show(): never { throw new Error(WIDGET_ERROR) },
		hide(): never { throw new Error(WIDGET_ERROR) },
		destroy(): never { throw new Error(WIDGET_ERROR) },
		switchTo(): never { throw new Error(WIDGET_ERROR) },
		isDestroyed(): never { throw new Error(WIDGET_ERROR) }
	},
	transferDynamicPhoneNumber,
	sendCustomLeadParams,
	changeDescriptor,
	getLeadQueryParams
}

export default function DentoloQuiz(options: Dentolo.Options): Dentolo.Api {
	getEmitterForYAMetrika(store.dentoloEventEmitter);
	store.dentoloEventEmitter.emit(DentoloEventName.CODE_LOADED, {});

	const webvisor = getWebvisorStatus();
	yaMetrikaCounter.init(webvisor).then(() => {
		yaMetrikaCounter.exec('getClientID', (clientID: string) => {
			store.yaClientID = clientID;
		});

		if (webvisor) enableWebvisor(appConfig().ya_metrika_counter);
	});

	checkOptionsForErrors(options);
	if (options.widget) checkWidgetOptionsForErrors(options.widget);

	store.clientId = options.clientId;
	store.loadingStrategy = store.loadingStrategy;
	store.autopopupTimeout = options.autopopupTimeout == undefined ? store.autopopupTimeout : options.autopopupTimeout;
	store.openOnScroll = options.openOnScroll || store.openOnScroll;
	store.initialPageIndex = options.initialPageIndex == undefined ? store.initialPageIndex : options.initialPageIndex;
	store.widgetOptions = options.widget;
	store.develop = options.develop;
	store.customLeadParams = options.customLeadParams;
	store.scenario = options.scenario || store.scenario;
	store.autopopupLimit = options.autopopupLimit == undefined ? store.autopopupLimit : options.autopopupLimit;
	store.whatsApp = options.whatsApp;
	store.commonServiceList = options.commonServiceList as (Service[] | undefined);
	store.showCommonServiceList = options.showCommonServiceList;
	store.bonuses = options.bonuses === undefined ? store.bonuses : options.bonuses;
	store.customIframe = options.iframe;
	store.customContent = options.customContent || store.customContent;
	store.closeButton = options.closeButton;
	store.enableGeolocation = options.enableGeolocation || store.enableGeolocation || false;
	store.startPageAutoAction = options.startPageAutoAction;

	store.quiz_host = options.quiz_host || (store.develop ? appConfig().quiz_develop_url : store.quiz_host);
	store.theme = store.quiz_host.includes("v1-dark/index.html") ? "dark" : "light";

	
	store.go_test = options.go_test == undefined ? store.go_test : options.go_test;
	
	store.serviceList = (options.serviceList as (Service[] | undefined)) == undefined ? store.serviceList : options.serviceList as Service[];
	store.servicesNum = store.serviceList.length;
	store.dentoloEventEmitter.emit(DentoloEventName.SEND_PARAMS, { servicesNum: store.servicesNum });

	window.dentolo_quiz = api;
	return api;
}

function scrollHandler(event: Event): void {
	const { pageYOffset, innerHeight } = window;
	if (!state.pristine) return scrollEmitter.unsubscribe(scrollHandler);
	if (pageYOffset + innerHeight * 1.1 >= document.body.offsetHeight) open(store.initialPageIndex, 'scroll');
}

function loadQuizHandler(event: any): void {
	store.iframe?.send(INIT, {
		clientId: store.clientId,
		serviceList: store.serviceList,
		initialPageIndex: store.initialPageIndex,
		queryParams: store.queryParams,
		startPageAutoAction: store.startPageAutoAction,
		customLeadParams: {
			...store.customLeadParams,
			yaClientID: store.yaClientID,
			roistat_visit: getCookie("roistat_visit"),
			roistat_first_visit: getCookie("roistat_first_visit"),
			serviceNum: store.serviceList.length,
			serviceList: store.serviceList,
			autopopupTimeout: store.autopopupTimeout,
			openOnScrollEnable: store.openOnScroll,
			waEnable: Boolean(store.whatsApp?.enable),
			scenario: store.scenario,
			theme: store.theme,
			os_name: store.uaParserResult?.os.name,
			os_version: store.uaParserResult?.os.version,
			device_model: store.uaParserResult?.device.model,
			device_brand: store.uaParserResult?.device.vendor,
			device_type: store.uaParserResult?.device.type,
			_ct_session_id: getCookie("_ct_session_id")
		},
		closeButton: store.closeButton,
		domain: document.domain,
		location: window.location.href,
		scenario: store.scenario,
		whatsApp: store.whatsApp,
		bonusesStatusFromPlugin: store.bonuses,
		accessKey: getQueryParam('api-key'),
		commonServiceList: store.commonServiceList,
		showCommonServiceList: store.showCommonServiceList
	});

	// const combFromQuery = getQueryParam('test_comb') || undefined;
	// console.log("combFromQuery", combFromQuery);
	// if (combFromQuery) applyGOTests(combFromQuery);
	// if (store.go_test && combFromQuery == undefined) applyGOTests(combFromQuery);

	// enable the best konvr variation
	getTheBestCombination();

	store.dentoloEventEmitter.subscribe(DentoloEventName.LOAD_CONTENT, () => {
		store.iframe?.send(CUSTOM_CONTENT, {
			customContent: store.customContent
		});
	})

}

async function getTheBestCombination() {
	store.testVariations = await getCombination(1);

	store.iframe?.send(TEST_VARIATIONS, {
		testVariations: store.testVariations
	});
}

function closeWithErrorHandler({ payload }: PostMessageData) {
	store.currentError = payload.data;
	store.widget?.destroy();
	setTimeout(() => close("error"), 5000);
}

async function init(): Promise<void> {
	store.iframe = new Iframe(store.quiz_host, store.clientId, store.serviceList, store.scenario, store.customIframe);
	if (store.scenario !== "custom") await store.iframe.appendToPage(); // надежно ли это отрабатвыает?
	if (store.scenario == 'fullPage') open(undefined, 'fullPage');
	if (store.scenario == 'custom') open(undefined, 'custom');
	store.dentoloEventEmitter.emit(DentoloEventName.IFRAME_ADDED, {});

	checkForEnable(store.clientId).then(result => {
		if (!result) {
			close();
			store.widget?.hide();
		}
	});

	store.dentoloEventEmitter.emit(DentoloEventName.SEND_PARAMS, { theme: store.theme });

	const uaParser = new UAParser();
	const uaParserResult = uaParser.getResult();
	store.uaParserResult = uaParserResult;

	api.open = open;
	api.close = close;
	api.transferDynamicPhoneNumber = transferDynamicPhoneNumber;
	api.sendCustomLeadParams = sendCustomLeadParams;

	store.iframe.subscribe(DentoloEventName.LOAD_QUIZ, loadQuizHandler);
	store.iframe.subscribe(DentoloEventName.CLOSE, () => {
		if (store.closeButton?.onClick) {
			window.open(store.closeButton.onClick, store.closeButton.target);
		} else close("close_button");
	});
	store.iframe.subscribe(DentoloEventName.CLOSE_WITH_ERROR, closeWithErrorHandler);

	Object.values(DentoloEventName).forEach(event => {
		store.iframe?.subscribe(event, (payload: any) => {
			store.dentoloEventEmitter.emit(event, { type: event, ...payload });
		});
	});

	if (store.enableGeolocation) {
		import('./geolocation').then(geolocationModule => {
			const geolocationPlugin = new geolocationModule.default(store.storageProvider);
			geolocationPlugin.getData().then(geolocation => {
				store.dentoloEventEmitter.emit(DentoloEventName.GET_GEODATA, { geolocation: geolocation });
				sendCustomLeadParams({ geolocation });
			});
		});
	}

	if (store.widgetOptions && store.scenario == 'popup') {
		import('./models/widget/widget.model').then(widgetModule => {
			if (store.widgetOptions) {
				store.widget = new widgetModule.default(store.widgetOptions, store.dentoloEventEmitter);

				store.widget.onClick((event: MouseEvent) => {
					console.log("widget onClick", event);
					open(
						store.widgetOptions?.initialPageIndex == undefined ? 1 : store.widgetOptions?.initialPageIndex,
						store.widget?.getCurrentMode() + "Widget"
					);
				});

				store.widget.render();

				if (store.widget) {
					api.widget = {
						show: store.widget.show.bind(store.widget),
						hide: store.widget.hide.bind(store.widget),
						destroy: store.widget.destroy.bind(store.widget),
						switchTo: store.widget.switchTo.bind(store.widget),
						isDestroyed: store.widget.isDestroyed.bind(store.widget)
					}
				}
			}
		});
	}

	const popupTimeout = store.storageProvider.getItem("popup");
	if (store.openOnScroll && !popupTimeout) scrollEmitter.subscribe(scrollHandler);

	setAutopopupTimeout();

	state.inited = true;
	store.dentoloEventEmitter.emit(DentoloEventName.INIT, { type: 'init' });

	return Promise.resolve();
}

async function open(index: number = store.initialPageIndex, trigger?: any) {
	if (store.currentError) throw Error(store.currentError);

	if (!state.inited) {
		await new Promise<void>(resolve => {
			store.dentoloEventEmitter.subscribe(DentoloEventName.INIT, () => resolve())
		})
	}

	if (store.iframe == undefined) return;
	if (store.iframe.isOpened()) return;

	if (!store.iframe.isLoaded()) {
		store.iframe.loadContent().then(() => {
			store.iframe?.send('OPEN', { initialPageIndex: index, trigger });
		});
	} else {
		store.iframe?.send('OPEN', { initialPageIndex: index, trigger });
	}

	store.memorizedScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

	if (store.scenario !== "custom") {
		setTimeout(() => {
			if (device.ios()) document.body.classList.add(styles.overflowHidden);
			document.documentElement.classList.add(styles.overflowHidden);
		}, 500);
	}

	store.iframe.show();

	if (!store.widget?.isDestroyed() && store.widget?.hideOnOpenQuiz) store.widget.hide();

	state.pristine = false;

	const expiryDate = new Date(new Date().getTime() + 1000 * 60 * 60 * store.autopopupLimit);
	store.storageProvider.addItem("popup", true, expiryDate);
}

function close(trigger: string = 'api') {
	if (store.currentError) throw Error(store.currentError);
	if (!state.inited) throw Error('Dentolo quiz is not inited');
	if (!store.iframe || !store.iframe.isOpened()) return;

	document.documentElement.scrollTop = store.memorizedScrollPosition;
	document.body.scrollTop = store.memorizedScrollPosition;

	store.iframe.hide();

	if (device.ios()) document.body.classList.remove(styles.overflowHidden);
	document.documentElement.classList.remove(styles.overflowHidden);
}

function bind(event: DentoloEventName, callback: Dentolo.Callback): void {
	store.dentoloEventEmitter.subscribe(event, callback);
}

function unbind(event: DentoloEventName, callback: Dentolo.Callback): void {
	store.dentoloEventEmitter.unsubscribe(event, callback);
}

function getLeadQueryParams() {
	return store.queryParams;
}

function setAutopopupTimeout(milliseconds: number = store.autopopupTimeout): void {
	const popupTimeout = store.storageProvider.getItem("popup");
	if (popupTimeout) return;

	store.autopopupTimeout = milliseconds;

	if (store.autopopupTimer) clearTimeout(store.autopopupTimer);

	if (store.autopopupTimeout !== 0) {
		store.autopopupTimer = setTimeout(() => !store.currentError && state.pristine && open(store.initialPageIndex, 'autopopup'),
			store.autopopupTimeout);
	}
}

async function transferDynamicPhoneNumber(phone: string): Promise<void> {
	const sendPhone = (resolve: Function) => {
		store.iframe?.send(REPLACE_PHONE, { phone });
		resolve();
	}

	const promiseExecutor = (resolve: Function) => {
		if (!store.iframe?.isLoaded()) {
			store.iframe?.subscribe(DentoloEventName.LOAD_QUIZ, () => sendPhone(resolve));
		} else sendPhone(resolve);
	};

	if (!state.inited) store.dentoloEventEmitter.subscribe(DentoloEventName.INIT, () => new Promise(promiseExecutor));
	else return new Promise(promiseExecutor);
}

async function changeDescriptor(descriptor: string): Promise<void> {
	const sendDescriptor = (resolve: Function) => {
		store.iframe?.send(REPLACE_DESCRIPTOR, { descriptor });
		resolve();
	}

	const promiseExecutor = (resolve: Function) => {
		if (!store.iframe?.isLoaded()) {
			store.iframe?.subscribe(DentoloEventName.LOAD_QUIZ, () => sendDescriptor(resolve));
		} else sendDescriptor(resolve);
	};

	if (!state.inited) store.dentoloEventEmitter.subscribe(DentoloEventName.INIT, () => new Promise(promiseExecutor));
	else return new Promise(promiseExecutor);
}

async function sendCustomLeadParams(customLeadParams: { [key: string]: any }): Promise<void> {
	const sendParams = (resolve: Function) => {
		store.iframe?.send(CUSTOM_LEAD_PARAMS, { customLeadParams });
		resolve();
	}

	const promiseExecutor = (resolve: Function) => {
		if (!store.iframe?.isLoaded()) {
			store.iframe?.subscribe(DentoloEventName.LOAD_QUIZ, () => sendParams(resolve));
		} else sendParams(resolve);
	};

	if (!state.inited) store.dentoloEventEmitter.subscribe(DentoloEventName.INIT, () => new Promise(promiseExecutor));
	else return new Promise(promiseExecutor);
}

function isInited(): boolean {
	return state.inited;
}

function getWebvisorStatus(): boolean {
	if (appConfig().ya_metrika_webvisor) {
		const whitelist = appConfig().ya_metrika_webvisor_whitelist;

		if (whitelist.length) {
			const hasMatch = whitelist.some(value => window.location.href.includes(value));
			if (hasMatch) return true;

			return false;

		} else return true;
	} else return false;
}

async function enableWebvisor(counter: number) {
	const sendParams = (resolve: Function) => {
		store.iframe?.send(ENABLE_WEBVISOR, { counter, domain: window.location.hostname });
		resolve();
	}

	const promiseExecutor = (resolve: Function) => {
		if (!store.iframe?.isLoaded()) {
			store.iframe?.subscribe(DentoloEventName.LOAD_QUIZ, () => sendParams(resolve));
		} else sendParams(resolve);
	};

	if (!state.inited) {
		return new Promise(resolve => {
			store.dentoloEventEmitter.subscribe(DentoloEventName.INIT, () => promiseExecutor(resolve));
		})
	} else return new Promise(promiseExecutor);
}